import React, { useEffect } from 'react'
import { ThemeProvider } from "@mui/material/styles"
import { CssBaseline, Box } from "@mui/material"
import { theme } from "./clioTheme"

const Layout = ({ children }) => {

  useEffect(() => {
    document.getElementById('site-wrap').classList.remove('nojs')
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box
        id="site-wrap"
        className="wrap nojs"
        sx={{
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        {children}
      </Box>
    </ThemeProvider>
  )
}

export default Layout
