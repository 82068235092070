exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-cat-werk-list-js": () => import("./../../../src/templates/catWerkList.js" /* webpackChunkName: "component---src-templates-cat-werk-list-js" */),
  "component---src-templates-hilfe-js": () => import("./../../../src/templates/hilfe.js" /* webpackChunkName: "component---src-templates-hilfe-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-keyword-werk-list-js": () => import("./../../../src/templates/keywordWerkList.js" /* webpackChunkName: "component---src-templates-keyword-werk-list-js" */),
  "component---src-templates-person-werk-list-js": () => import("./../../../src/templates/personWerkList.js" /* webpackChunkName: "component---src-templates-person-werk-list-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/searchPage.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-uniformtitle-werk-list-js": () => import("./../../../src/templates/uniformtitleWerkList.js" /* webpackChunkName: "component---src-templates-uniformtitle-werk-list-js" */),
  "component---src-templates-werk-js": () => import("./../../../src/templates/werk.js" /* webpackChunkName: "component---src-templates-werk-js" */)
}

