import {
  createTheme,
  responsiveFontSizes
} from '@mui/material/styles';

let theme = createTheme({
  palette: {
    type: 'light',
    primary: { // gold
      light: '#c5a326',
      main: '#A3871F',
      dark: '#7A6410',
      transparent: 'rgba( 34, 28, 7, 0.85)'
    },
    secondary: { // grün
      light: '#C9DDC9',
      main: '#92BA92',
      dark: '#405540',
      transparent: 'rgba( 28, 41, 28, 0.9)'
    },
    text: {
      menu: '#FAFAFA',
      menuhover: 'A3871F'
    }
  },
  shape: {
    borderRadius: 2
  },
  typography: {
    h2: {
      fontSize: '1.8rem'
    },
    h3: {
      fontSize: '1.666rem',
      fontWeight: 400
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400
    }
  }
})

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          '&.Mui-disabled': {
            color: '#ededed',
            backgroundColor: '#94A975',
            boxShadow: 'inherit'
          }
        },
        containedSecondary: {
          '&.Mui-disabled': {
            color: '#ededed',
            backgroundColor: '#c1a75f',
            boxShadow: 'inherit'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '& a': {
            width: '100%',
            color: 'rgba(0, 0, 0, 0.66)',
            textDecoration: 'none'
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 3
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200]  
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(1),
          paddingLeft: '0',
          paddingBottom: theme.spacing(1),
          paddingRight: '0'
        },
        title: {
          fontWeight: 400,
          lineHeight: '1.333',
          fontSize: '1rem',
          color: theme.palette.grey[700]
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          lineHeight: '1.333',
          paddingTop: theme.spacing(1),
          paddingLeft: '0',
          paddingRight: '0'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            color: theme.palette.grey[600]
          },
          '&:hover label': {
            color: theme.palette.grey[900]
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.grey[900]
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#9f2726"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "primary"
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export { theme };